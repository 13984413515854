import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ExternLink from '../components/externLink'

import dev_img from '../images/dev_activity.svg'
import game from '../images/game.svg'
import goal from '../images/goal.svg'

const BlogPage = () => {
	return (
		<Layout>
			<SEO
				description="Here's some detailed information about me."
				keywords={[`anshumanv`, `about`, `anshumanv's information`]}
				title="About"
			/>
			<section className="flex flex-col h-full">
				<h1 className="text-3xl leading-loose">About</h1>

				<span>What I do, what I like, what consumes most of my time etc.</span>

				<div className="flex flex-col md:flex-row my-10">
					<div className="flex flex-col mr-0 md:mr-8">
						<h2 className="text-2xl leading-loose">Software Engineering 👨‍💻</h2>
						<span>
							Been engineering softwares for almost 2 years now, JavaScript is
							something that I have worked with the most and feel most
							comfortable with. I've also contributed to several open source
							projects and built some of my own which are all available on my
							{` `}
							<ExternLink
								className="link"
								href="https://github.com/anshumanv"
								title="Anshuman's GitHub"
							>
								GitHub
							</ExternLink>
							.
						</span>
					</div>
					<img
						alt="anshumanv developer"
						className="w-full mt-5 md:mt-0 md:w-1/2 rounded-full"
						src={dev_img}
					/>
				</div>
				<div className="flex flex-col md:flex-row my-10">
					<div className="flex flex-col md:mr-8">
						<h2 className="text-2xl leading-loose">Anime 🗼</h2>
						<span>
							Anime is a super important part of my life, they have pretty
							trivial teachings but I'm fascinated to how they mirror the real
							life, it's something that lifts my mood, motivates me and help me
							have a persistent growth mindset at all times. Also, to be able to
							complete{` `}
							<ExternLink className="link" href="http://is.gd/anshuanime">
								100 distinct animes
							</ExternLink>
							{` `}
							before my graduation is something that I'm proud of.
						</span>
					</div>
					<img
						alt="anshumanv developer"
						className="w-full mt-5 md:mt-0 md:w-1/2 rounded"
						src="https://media.giphy.com/media/J6JazAkCVLId91L4yM/giphy.gif"
					/>
				</div>
				<div className="flex flex-col md:flex-row my-10">
					<div className="flex flex-col md:mr-8">
						<h2 className="text-2xl leading-loose">Football ⚽</h2>
						<span>
							Actively pursued football in school and for the first 2 years of
							my engineering(mostly strike, LW), made great friends because of
							it and had fun playing alongside so many amazing people. I also
							enjoy doing some lame freestyle and make them my{` `}
							<ExternLink
								className="link"
								href="https://www.instagram.com/anshumaniac/"
							>
								IG
							</ExternLink>
							{` `}
							story! Scoring in the Inter-IIIT finals and then winning the
							entire thing was satisfying. Can't wait to continue!
						</span>
					</div>
					<img
						alt="anshumanv developer"
						className="w-full mt-5 md:mt-0 md:w-1/2 rounded-full"
						src={goal}
					/>
				</div>
				<div className="flex flex-col md:flex-row my-10">
					<div className="flex flex-col md:mr-8">
						<h2 className="text-2xl leading-loose">Other Interests 🎮🏓✈️</h2>
						<span>
							I started playing CS:GO as well, initially just to try it but then
							ended up playing it 600+ hrs, I've also played a ton of table
							tennis with my batch-mates and enjoyed it alot as well, apart from
							that I recently realized I like travelling as well and was lucky
							enough to do a Japan trip which I've been looking forward to for
							the longest time.
						</span>
					</div>
					<img
						alt="anshumanv developer"
						className="mt-5 md:mt-0 md:w-1/2 w-full rounded-full"
						src={game}
					/>
				</div>
			</section>
			<h2 className="text-2xl leading-loose">Some Background ⏪</h2>
			<span>
				Ever since my childhood I've been interested in tech, computers etc. And
				so I knew that I had to pursue computers when I grow up. Trying
				different OSs was my favorite weekend thing (and completing silly
				Pokémon emulator games on NDS, GBA). Was always tweaking my computers
				and phones with different mods. After studying PCM for some years,
				managed to gain admission and pursue undergratuate in Computer Science
				and Engineering. Got hooked to JavaScript when I started coding and was
				fascinated about the ecosystem. I was lucky enough to have people
				support me in all hobbies and activities that I pursued and had a really
				fulfilling life so far.
			</span>
		</Layout>
	)
}

export default BlogPage
